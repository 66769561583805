import { createTheme } from '@mui/material/styles'
import { Palette, PaletteColor, TypeBackground } from '@mui/material/styles/createPalette'

interface CustomPaletteColor extends PaletteColor {
  main: string
}

interface CustomBackground extends TypeBackground {
  default: string
}

interface CustomPalette extends Palette {
  primary: CustomPaletteColor,
  background: CustomBackground,
}

declare module '@mui/styles' {
  interface DefaultTheme {
    palette: CustomPalette,
    spacing: (factor?: number) => string, // eslint-disable-line no-unused-vars
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#112638',
    },
    secondary: {
      main: '#112638',
    },
    background: {
      paper: '#fff',
      default: '#fffff',
    },
  },
  typography: {
    fontFamily: 'Inter, Muli, Arimo, sans-serif',
  },
  spacing: (factor = 0) => `${0.25 * factor}rem`,
})

export default theme