import React from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px',
    border: ({ color }: { color: string }) => `1px solid ${color}`,
    background: ({ color }: { color: string }) => color,
    padding: '20px',
    margin: '10px',
  },
  item: {
    display: 'flex',
    color: '#777777',
    justifyContent: 'space-between',
    margin: 5,
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  key: {
    display: 'flex',
    alignItems: 'center',
  },
}), { name: 'Card' })

interface Props {
  color: string,
  label: string,
  data: {
    key: string,
    value: string,
    icon: React.FunctionComponent,
  }[]
}

const Card = ({
  color,
  label,
  data,
}: Props): JSX.Element => {
  const classes = useStyles({ color })
  
  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {label}
      </div>
      {data.map(({ key, value, icon: Icon }) => (
        <div className={classes.item} key={key}>
          <div className={classes.key}>
            <Icon />
            <span style={{ margin: document.body.dir === 'ltr' ? '0 0 0 10px' : '0 10px 0 0' }}>{key}</span>
          </div>
          <div>
            {value}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Card
