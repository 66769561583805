import React, { ChangeEventHandler, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Paper, InputBase, IconButton } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'

export interface SearchBarProps {
  /**
   * Underlying text to display when there is no value
   */
  placeholder?: string
  /**
   * State value to apply on input
   */
  value: string
  /**
   * Change event handler
   */
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  /**
   * Language direction
   */
  isRtl?: boolean
  /**
   * The background color
   */
  backgroundColor?: string
  /**
   * A box shadow
   */
  boxShadow?: string
  /**
   * Margin in spacing units
   */
  margin?: number
  /**
   * padding
   */
  padding?: string
}

type StylesProps = {
  isRtl: boolean
  backgroundColor: string
  padding: string
}

const useStyles = makeStyles(() => ({
  root: {
    // width: '98%',
    padding: ({ padding }: StylesProps) => padding,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: ({ backgroundColor }: StylesProps) => `${backgroundColor} !important`,
    direction: ({ isRtl }: StylesProps) => (isRtl ? 'rtl' : 'ltr'),
  },
  input: {
    marginLeft: ({ isRtl }: StylesProps) => (isRtl ? 0 : '0.4rem'),
    marginRight: ({ isRtl }: StylesProps) => (isRtl ? '0.4rem' : 0),
    flex: 1,
    backgroundColor: 'transparent',
    fontSize: '1rem',
    fontFamily: 'inherit',
  },
}), { name: 'SearchBar' })

export default function SearchBar({
  placeholder = 'Search',
  value,
  onChange,
  isRtl = false,
  backgroundColor = 'rgb(230, 230, 230)',
  boxShadow = '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  margin = 0,
  padding = '0px 4px',
}: SearchBarProps): JSX.Element {
  const classes = useStyles({ isRtl, backgroundColor, padding })
  const [initital, setInitial] = useState(value ?? '')

  const handleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    if (onChange) {
      onChange(e)
    }

    setInitial(e.target.value)
  }

  useEffect(() => {
    if (value != null) {
      setInitial(value)
    }
  }, [value])

  return (
    <Paper component="div" className={classes.root} sx={{ boxShadow, m: margin }} >
      <InputBase
        className={classes.input}
        value={initital}
        onChange={handleChange}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
      />
      <IconButton disabled>
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}
