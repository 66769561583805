import React, { useState } from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import getRandomColor from '../../functions/get-random-color'

export interface ButtonProps {
  /**
   * Button text content
   */
  label: string
  /**
   * Whether or not to invert the styles
   */
  invert?: boolean
  /**
   * What background color to use
   */
  backgroundColor?: string
  /**
   * Text alignment
   */
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center'
  /**
   * Button type
   */
  type?: 'button' | 'submit'
  /**
   * Optional click handler
   */
  onClick?: () => void
}

type StylesProps = {
  color: string
  backgroundColor: string
  textAlign: 'start' | 'end' | 'left' | 'right' | 'center'
  invert: boolean
}

const useStyles = makeStyles(() => createStyles({
  root: {
    minWidth: 170,
    height: 50,
    padding: '1.2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: ({ textAlign }: StylesProps) => textAlign,
    border: '1px solid',
    borderRadius: '3px',
    borderColor: ({ invert }: StylesProps) => invert ? 'whitesmoke' : '#111',
    backgroundColor: ({ backgroundColor }: StylesProps) => backgroundColor,
    color: ({ invert }: StylesProps) => invert ? 'whitesmoke' : '#111',
    fontWeight: 600,
    fontFamily: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
    transitionDuration: '0.2s',
    transitionProperty: 'background-color, border-color, color',
    transitionTimingFunction: 'ease-out',
    '@media (max-width: 768px)': {
      minWidth: 140,
      height: 45,
      padding: '0.9rem',
    },
    '&:hover': {
      borderColor: ({ invert, color }: StylesProps) => invert ? 'whitesmoke' : color,
      color: ({ invert, color }: StylesProps) => invert ? 'whitesmoke' : color,
      backgroundColor: ({ invert, color }: StylesProps) => invert ? color : 'whitesmoke',
    },
  },
}), { name: 'Button' })

export default function Button({
  label = 'button',
  invert = false,
  backgroundColor = invert ? '#111' : 'whitesmoke',
  textAlign = 'start',
  type = 'button',
  ...props
}: ButtonProps): JSX.Element {
  const [color, setColor] = useState(getRandomColor())
  const classes = useStyles({ backgroundColor, color, textAlign, invert })
  
  return (
    <button
      type={type}
      className={classes.root}
      onMouseLeave={() => setColor(getRandomColor())}
      {...props}
    >
      {label}
    </button>
  )
}
