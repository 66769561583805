const {
  REACT_APP_SERVER_URI: SERVER_URI = 'http://localhost:3004',
  REACT_APP_PLAID_CLIENT_ID: PLAID_CLIENT_ID = '',
  REACT_APP_PROVIDER: PROVIDER = 'Everybyte',
} = process.env

export {
  SERVER_URI,
  PLAID_CLIENT_ID,
  PROVIDER,
}
