let counter = 0
const COLORS = ['#ecba82', '#18ca92', '#1274e3', '#6e32e5', '#f3455c']

const getRandomColor = (): string => {

  const index = (() => {
    if (counter < COLORS.length - 1) {
      counter++
    } else {
      counter = 0
    }

    return counter % COLORS.length
  })()

  return COLORS[index]
}

export default getRandomColor
