import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { AccountCircleOutlined, DescriptionOutlined, MoneyOutlined } from '@mui/icons-material'
import Button from '../generic/Button'
import Loader from '../generic/Loader'
import Card from '../generic/Card'
import PayImage from '../../assets/pay.png'
import { PayData } from '../../@types'

const useStyles = makeStyles((theme) => ({
  image: {
    width: '10rem',
    margin: '0 auto auto auto',
  },
  cardContainer: {
    margin: '1em 0',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.5,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
    width: '100%',
  },
  border: {
    opacity: 0.5,
    borderBottom: '1px solid black',
    width: '100%',
  },
  content: {
    padding: '0 10px 0 10px',
  },
}), { name: 'Summary' })

interface Props {
  nextStep: () => void
  loading: boolean
  paymentData: PayData | null
}

export default function Summary({
  nextStep,
  loading,
  paymentData,
}: Props): JSX.Element {
  const { t } = useTranslation()
  const classes = useStyles()

  const clickConfirm = (): void => {
    if (paymentData) { 
      nextStep()
    } else {
      alert('No payment information was provided, check query: /pay?paymentId={paymentId}')
    }
  }

  const clickDeny = (): void => {
    alert('not supported yet')
  }

  const orderInfoCard = [
    {
      key: t('summary_1'),
      value: paymentData?.recipientName ?? '-',
      icon: () => (<AccountCircleOutlined />)
    },
    {
      key: t('summary_2'),
      value: paymentData?.description ?? '-',
      icon: () => (<DescriptionOutlined />)
    },
    {
      key: t('summary_3'),
      value: `${paymentData?.amount.label ?? 0} ${paymentData?.currency.toUpperCase() ?? ''}`,
      icon: () => (<MoneyOutlined />)
    },
  ]

  return (
    loading ? (
      <Loader />
    ) : (
      <>
        <div className={classes.cardContainer}>
          <img src={PayImage} alt='' className={classes.image} />
          <Card color="#f3f0f9" label={t('summary_0')} data={orderInfoCard} />
        </div>
        
        <div className={classes.buttonContainer}>
          <Button
            label={t('Confirm')}
            invert
            textAlign='center'
            onClick={clickConfirm}
          />
          <div className={classes.divider}>
            <div className={classes.border} />
            <span className={classes.content}>
              {t('OR')}
            </span>
            <div className={classes.border} />
          </div>
          <Button
            label={t('Deny')}
            textAlign='center'
            onClick={clickDeny}
          />
        </div>
      </>
    )
  )
}
