/* eslint-disable max-len */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchBar from '../../../generic/SearchBar'
import BankList from '../../../generic/BankList'
import { MiddleEastBanks } from '../../../../@types'

interface Props {
  nextStepOpenFinance: () => void
  setSelectedBank: React.Dispatch<React.SetStateAction<string>>
}

export default function SelectBank({ setSelectedBank, nextStepOpenFinance }: Props): JSX.Element {
  const { t } = useTranslation()
  const [value, setValue] = useState('')

  const middleEastBanks: MiddleEastBanks = [
    {
      _id: 'mizrahi',
      bankId: t('Mizrahi'),
      site: 'www.mizrahi-tefahot.co.il',
      image: 'https://www.maala.org.il/wp-content/uploads/2015/07/%D7%91%D7%A0%D7%A7-%D7%9E%D7%96%D7%A8%D7%97%D7%99-%D7%98%D7%A4%D7%97%D7%95%D7%AA.png',
      number: '777',
    },
    {
      _id: 'leumi',
      bankId: t('Leumi'),
      site: 'www.leumi.co.il',
      image: 'https://softwares.center/wp-content/uploads/2017/02/9445646.png',
      number: '777',
    },
    {
      _id: 'discount',
      bankId: t('Discount'),
      site: 'www.discountbank.co.il',
      image: 'https://yt3.ggpht.com/ytc/AKedOLTFpzXVupjFjsCp_A-YU565qvRtDHJbtaUdcb_X=s900-c-k-c0x00ffffff-no-rj',
      number: '777',
    },
    {
      _id: 'fibi',
      bankId: t('Beinleumi'),
      site: 'www.fibi.co.il',
      image: 'https://www.theta1.co.il/wp-content/uploads/2021/02/%D7%91%D7%99%D7%A0%D7%9C%D7%90%D7%95%D7%9E%D7%99-%D7%9C%D7%95%D7%92%D7%95.jpg',
      number: '777',
    },
    {
      _id: 'bankhapoalim',
      bankId: t('Bank Hapoalim'),
      site: 'www.bankhapoalim.co.il',
      image: 'https://yt3.ggpht.com/ytc/AKedOLRXzOxFGt3F_UHfgcGgD2fmk9t3DfCGewdbAKqo=s900-c-k-c0x00ffffff-no-rj',
      number: '777',
    },
    {
      _id: 'mercantile',
      bankId: t('Mercantile'),
      site: 'www.mercantile.co.il',
      image: 'https://ramot-mall.co.il/wp-content/uploads/2019/12/LOGO-43.png',
      number: '777',
    },
    {
      _id: 'pepper',
      bankId: t('Pepper'),
      site: 'www.pepper.co.il',
      image: 'https://www.pepper.co.il/wp-content/themes/pepper/images/logo.svg',
      number: '777',
    },
    {
      _id: 'jerusalem',
      bankId: t('Jerusalem'),
      site: 'www.bankjerusalem.co.il',
      image: 'https://mbr.co.il/wp-content/uploads/2020/09/13212313.jpg',
      number: '777',
    },
    {
      _id: 'yahav',
      bankId: t('Yahav'),
      site: 'www.bank-yahav.co.il',
      image: 'https://www.bank-yahav.co.il/SiteData/1/Templates/Yahav/img/logo.png',
      number: '777',
    },
    {
      _id: 'igud',
      bankId: t('Igud'),
      site: 'www.unionbank.co.il',
      image: 'https://www.unionbank.co.il/Igud/Img/Logo_Main.png',
      number: '777',
    },
    {
      _id: 'digibank',
      bankId: t('Digital Bank'),
      site: 'www.digibank.co.il/',
      image: 'https://www.digibank.co.il/Content/images/footer-logo.svg',
      number: '777',
    },
  ]

  const filteredBanks = Object.values(middleEastBanks).filter(({ bankId }) => bankId.toLowerCase().includes(value))

  return (
    <div>
      <SearchBar
        placeholder={t('Search Banks')}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        isRtl={document.body.dir === 'rtl'}
      />

      <BankList
        banks={filteredBanks}
        isButton={true}
        setSelectedBank={setSelectedBank}
        nextStepOpenFinance={nextStepOpenFinance}
      />
    </div>
  )
}
