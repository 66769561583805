import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { List, ListItem } from '@mui/material'
import WorldMap from 'react-world-map'
import openFinanceLogo from '../../assets/logos/open-finance.png'
import tinkLogo from '../../assets/logos/tink.png'
import plaidLogo from '../../assets/logos/plaid.jpg'
import { Platforms, Continent } from '../../@types'

const useStyles = makeStyles(({ spacing }) => ({
  listItem: {
    width: '90% !important',
    margin: '11px auto !important',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgb(252, 252, 252) !important',
    border: '1px solid #000 !important',
    borderRadius: '11px !important',
    '&:hover': {
      backgroundColor: 'rgba(245, 245, 245) !important',
    },
  },
  logo: {
    margin: ({ isRtl }: { isRtl: boolean }) => isRtl ? `0 0 0 ${spacing(2)}` : `0 ${spacing(2)} 0 0`,
    display: 'inline-grid',
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyItems: 'center',
    borderRadius: '50%',
    fontSize: 16,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainText: {
    fontWeight: 500,
    display: 'inline-flex',
    fontSize: 19,
    margin: spacing(),
    marginLeft: 0,
    marginRight: 0,
  },
  subtext: {
    marginBottom: spacing(),
    display: 'inline-flex',
    color: 'rgb(200, 200, 200)',
  },
}), { name: 'ChoosePlatform' })

interface Props {
  nextStep: () => void
  setPaymentProivder: React.Dispatch<React.SetStateAction<string>>
}

export default function ChoosePlatform({ nextStep, setPaymentProivder }: Props): JSX.Element {
  const { t } = useTranslation()
  const classes = useStyles({ isRtl: document.body.dir === 'rtl' })
  const [selected, setSelected] = useState<string | null>(null)

  const clickListItem = (name: Continent): void => {
    switch (name) {
    case 'as':
    {
      setPaymentProivder('open-finance')
      nextStep()
      break
    }

    case 'eu':
    {
      alert('not supported yet')
      break
    }

    case 'na':
    {
      setPaymentProivder('plaid')
      nextStep()
      break
    }

    default: {
      console.warn('unexpected dev error')
      break
    }
    }
  }

  const platforms: Platforms = [
    {
      _id: 'as',
      name: t('Middle East'),
      subtext: 'Open-Finance',
      image: openFinanceLogo,
    },
    {
      _id: 'eu',
      name: t('Europe'),
      subtext: 'Tink',
      image: tinkLogo,
    },
    {
      _id: 'na',
      name: t('USA & Canada'),
      subtext: 'Plaid',
      image: plaidLogo,
    },
  ]

  return (
    <div>
      <WorldMap selected={selected} />

      <List aria-label="banks list">
        {platforms.map(({ image, name, _id, subtext }) => (
          <ListItem
            key={_id}
            className={classes.listItem}
            button
            onClick={() => clickListItem(_id)}
            onMouseEnter={() => setSelected(_id)}
            onMouseLeave={() => setSelected(null)}
          >
            <div
              className={classes.logo}
              role="button"
              tabIndex={0}
              style={{
                backgroundImage: `url(${image})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            />

            <div className={classes.textContainer}>
              <div className={classes.mainText}>
                {name}
              </div>
              <div className={classes.subtext}>
                {subtext}
              </div>
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  )
}
