import React from 'react'
import { makeStyles } from '@mui/styles'
import { List, ListItem } from '@mui/material'
import { MiddleEastBanks } from '../../@types'

const useStyles = makeStyles(({ spacing, palette }) => ({
  namesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    width: '100%',
  },
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  listItem: {
    display: 'flex',
    direction: ({ isRtl }: { isRtl?: boolean }) => (isRtl ? 'rtl' : 'ltr'),
    flexDirection: 'column',
    opacity: 0,
    animation: '$fade-up 0.25s ease-in',
    animationFillMode: 'forwards',
  },
  listIndex: {
    marginRight: ({ isRtl }: { isRtl?: boolean }) => (isRtl ? 0 : spacing(2)),
    marginLeft: ({ isRtl }: { isRtl?: boolean }) => (isRtl ? spacing(2) : 0),
    display: 'inline-grid',
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyItems: 'center',
    background: palette.grey[400],
    borderRadius: '50%',
    fontSize: 16,
  },
  bankText: {
    fontWeight: 500,
    display: 'inline-flex',
    fontSize: 19,
    margin: spacing(),
    marginLeft: 0,
    marginRight: 0,
  },
  line: {
    width: 50,
    height: 50,
    borderRadius: '50%',
  },
  site: {
    marginBottom: spacing(),
    display: 'inline-flex',
    color: 'rgb(200, 200, 200)',
  },
  '@keyframes fade-up': {
    from: {
      left: -20
    },
    to: {
      opacity: 1,
      left: 0,
    },
  },
}), { name: 'BankList' })

interface Props {
  banks: MiddleEastBanks
  isButton: boolean
  nextStepOpenFinance?: () => void
  setSelectedBank?: React.Dispatch<React.SetStateAction<string>>
}

export default function BankList({ banks, isButton, setSelectedBank, nextStepOpenFinance }: Props): JSX.Element {
  const classes = useStyles({ isRtl: document.body.dir === 'rtl' })

  const onClick = (id: string): void => {
    if (id !== 'Mizrahi' && id !== 'מזרחי') {
      alert('not supported yet')
    } else { 
      setSelectedBank && setSelectedBank(id)
      nextStepOpenFinance && nextStepOpenFinance()
    }
  }

  return (
    <List aria-label="banks list" className={classes.list}>
      {banks.map(({ image, bankId, _id, site }, idx) => (
        <ListItem
          key={_id}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          button={isButton}
          onClick={() => onClick(bankId)}
          className={classes.listItem}
          style={{ animationDelay: `${idx * 100}ms` }}
        >
          <div className={classes.container}>
            <div className={classes.listIndex}>
              <div
                className={classes.line}
                role="button"
                tabIndex={0}
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            </div>
            <div className={classes.namesContainer}>
              <div className={classes.bankText} aria-selected={!isButton}>
                {bankId}
              </div>
              <div className={classes.site}>
                {site}
              </div>
            </div>
          </div>
        </ListItem>
      ))}
    </List>
  )
}
