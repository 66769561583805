import React, { useState } from 'react'
import { PayData } from '../../../../@types'
import SelectBank from './SelectBank'
import AuthorizeBank from './AuthorizeBank'

interface Props {
  nextStep: () => void
  paymentData: PayData | null
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

export default function OpenFinance({
  nextStep,
  paymentData,
  setIsSuccess,
}: Props): JSX.Element {
  const [stepOpenFinance, setStepOpenFinance] = useState(0)
  const [selectedBank, setSelectedBank] = useState('')

  const clickForward = (): void => {
    setStepOpenFinance((prev) => {
      if (prev === 1) {
        return prev
      }

      return prev + 1
    })
  }

  const makePayment = (success: boolean): void => {
    setIsSuccess(success)
    nextStep()
  }

  switch (stepOpenFinance) {
  case 0:
    return <SelectBank nextStepOpenFinance={clickForward} setSelectedBank={setSelectedBank} />

  case 1:
    return <AuthorizeBank selectedBank={selectedBank} paymentData={paymentData} makePayment={makePayment} />

  default:
    return <div />
  }
}
