import React from 'react'
import { PayData } from '../../../@types'
import OpenFinance from './OpenFinance'
import Plaid from './Plaid'

interface Props {
  nextStep: () => void
  paymentProvider: string
  paymentData: PayData | null
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Checkout({ nextStep, paymentProvider, paymentData, setIsSuccess }: Props): JSX.Element {
  switch (paymentProvider) {
  case 'open-finance':
    return <OpenFinance nextStep={nextStep} paymentData={paymentData} setIsSuccess={setIsSuccess} />
  
  case 'plaid':
    return <Plaid nextStep={nextStep} paymentId={paymentData?._id ?? ''} setIsSuccess={setIsSuccess} />
  
  default:
    return <div />
  }
}
