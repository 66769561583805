import React, { useEffect, useState } from 'react'
import { PLAID_CLIENT_ID, SERVER_URI } from '../../../../consts'
import Loader from '../../../generic/Loader'
import Link from './Link'

interface Props {
  nextStep: () => void
  paymentId: string
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Plaid({ nextStep, paymentId, setIsSuccess }: Props): JSX.Element {
  const [load, setLoad] = useState(false)
  const [accountId, setAccountId] = useState('')
  const [linkToken, setLinkToken] = useState('')
  const [publicToken, setPublicToken] = useState('')

  const getLinkToken = async (): Promise<void> => {
    setLoad(true)
    try {
      const response = await fetch(`${SERVER_URI}/create-plaid-link`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${null}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: PLAID_CLIENT_ID,
          clientName: 'Everybyte',
          products: ['auth'],
          countryCodes: ['US'],
          language: 'en',
          webhook: 'https://everybyte.io',
        }),
      })

      const { linkToken: token } = await response.json()

      setLinkToken(token)
    } catch (error) {
      console.error(error)
    }
    setLoad(false)
  }

  const makePayment = async (): Promise<void> => {
    setLoad(true)
    try {
      const response = await fetch(`${SERVER_URI}/pay-with-stripe`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${null}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentId,
          accountId,
          publicToken,
        }),
      })

      if (response.status >= 200 && response.status <= 299) {
        const { charge } = await response.json()

        console.log(charge)
        setIsSuccess(true)
      } else {
        setIsSuccess(false)
      }
    } catch (error) {
      console.error(error)
      setIsSuccess(false)
    }
    nextStep()
  }

  useEffect(() => {
    if (!linkToken) getLinkToken()
  }, [linkToken]) // eslint-disable-line

  useEffect(() => {
    if (paymentId && accountId && publicToken) makePayment()
  }, [paymentId, publicToken, accountId]) // eslint-disable-line

  return (
    <>
      {load ? (
        <Loader />
      ) : linkToken && !publicToken ? (
        <Link
          linkToken={linkToken}
          setLinkToken={setLinkToken}
          setPublicToken={setPublicToken}
          setAccountId={setAccountId}
        />
      ) : null}
    </>
  )
}
