import React from 'react'
import { createStyles, makeStyles } from '@mui/styles'

export interface LinkButtonProps {
  /**
   * Button text content
   */
  label: string
  /**
   * Button type
   */
  type?: 'button' | 'submit'
  /**
   * Text font size
   */
  fontSize?: string | number
  /**
   * Text font weight
   */
  fontWeight?: number
  /**
   * Optional click handler
   */
  onClick?: () => void
}

type StylesProps = {
  fontSize: string | number
  fontWeight: number
}

const useStyles = makeStyles(() => createStyles({
  root: {
    minWidth: 110,
    height: 50,
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
    border: 'none',
    color: '#111',
    textAlign: 'center',
    fontFamily: 'inherit',
    fontSize: ({ fontSize }: StylesProps) => fontSize,
    fontWeight: ({ fontWeight }: StylesProps) => fontWeight,
    cursor: 'pointer',
    '@media (max-width: 768px)': {
      minWidth: 90,
      height: 45,
      padding: '0.9rem',
    },
    '&:hover': {
      '& div': {
        width: '100%',
      }
    },
  },
  bar: {
    width: '0',
    height: '0.2rem',
    marginTop: '0.7rem',
    display: 'block',
    backgroundColor: '#111',
    borderRadius: '1rem',
    transitionDuration: '0.2s',
    transitionProperty: 'width',
    transitionTimingFunction: 'ease',
  },
}), { name: 'LinkButton' })

export default function LinkButton({
  label = 'button',
  type = 'button',
  fontSize = '1.1rem',
  fontWeight = 600,
  ...props
}: LinkButtonProps): JSX.Element {
  const classes = useStyles({ fontSize, fontWeight })
  
  return (
    <button
      type={type}
      className={classes.root}
      {...props}
    >
      {label}
      <div className={classes.bar} />
    </button>
  )
}
