/* eslint-disable max-len */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PayData } from '../../../../@types'
import { PROVIDER } from '../../../../consts'
import styles from './AuthorizeBank.module.css'

interface Props {
  selectedBank: string
  paymentData: PayData | null
  makePayment: (success: boolean) => void // eslint-disable-line no-unused-vars
}

export default function AuthorizeBank({ selectedBank = PROVIDER, paymentData, makePayment }: Props): JSX.Element {
  const { t } = useTranslation()
  const [id, setId] = useState('')
  const [pass, setPass] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const clickContinue = (): void => {
    if (!id || !pass) {
      return alert('bad credentials')
    }

    setSubmitted(true)
  }

  const clickConfirm = (): void => {
    makePayment(true)
  }

  const clickReject = (): void => {
    makePayment(false)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1>{t('Bank')} {selectedBank}</h1>

        <div className={`${styles.container} ${styles.form}`}>
          {!submitted ? (
            <>
              <input placeholder={t('Enter ID')} type="id" value={id} onChange={(e) => setId(e.target.value)} />
              <input placeholder={t('Enter password')} type="password" value={pass} onChange={(e) => setPass(e.target.value)} />
              <button onClick={clickContinue}>{t('Continue')}</button>
            </>
          ) : (
            <>
              <div className={styles.summaryContainer}>
                <div className={styles.summaryItem}>
                  <span>{t('summary_1')}</span> <span>{paymentData?.recipientName}</span>
                </div>
                <div className={styles.summaryItem}>
                  <span>{t('summary_2')}</span> <span>{paymentData?.description ?? '-'}</span>
                </div>
                <div className={styles.summaryItem}>
                  <span>{t('summary_3')}</span> <span>{paymentData?.amount.label} {paymentData?.currency.toUpperCase()}</span>
                </div>
              </div>

              <div className={styles.buttonsWrapper}>
                <button onClick={clickConfirm}>{t('Confirm')}</button>
                <button onClick={clickReject}>{t('Deny')}</button>
              </div>
            </>
          )}
        </div>
      </div>

      <ul className={styles.bgBubbles}>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  )
}
