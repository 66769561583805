import React, { useEffect } from 'react'
import { PROVIDER } from './consts'
import Pay from './pages/Pay'

const App = (): JSX.Element => {
  useEffect(() => {
    document.title = PROVIDER
  }, [])

  return (
    <div>
      <Pay />
    </div>
  )
}

export default App
