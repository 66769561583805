/* eslint-disable max-len */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { InputAdornment, TextField, Typography } from '@mui/material'
import { Email } from '@mui/icons-material'
import Button from '../generic/Button'
import ReceiptImage from '../../assets/receipt.png'
import Loader from '../generic/Loader'

const useStyles = makeStyles(() => ({
  inputContainer: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  image: {
    width: '10rem',
    margin: '0 auto 1rem auto',
  },
  input: {
    '& .MuiInputBase-root': {
      backgroundColor: 'rgb(252, 252, 252) !important',
    },
  },
  text: {
    fontSize: '0.9rem !important',
  },
  error: {
    color: 'red',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}), { name: 'CollectEmail' })

interface Props {
  nextStep: () => void
  loading: boolean
  receiptEmail: string
  updateEmail: (email: string) => Promise<void> // eslint-disable-line no-unused-vars
}

export default function CollectEmail({
  nextStep,
  loading,
  receiptEmail,
  updateEmail,
}: Props): JSX.Element {
  const { t } = useTranslation()
  const classes = useStyles()

  const [email, setEmail] = useState<string>(receiptEmail)
  const [error, setError] = useState<false | string>(false)

  const clickContinue = async (): Promise<void> => {
    try {
      await updateEmail(email)
      nextStep()
    } catch (error: any) { // eslint-disable-line
      console.error(error.message)
      setError(error.message)
    }
  }

  return (
    loading ? (
      <Loader />
    ) : (
      <>
        <div className={classes.inputContainer}>
          <img src={ReceiptImage} alt='' className={classes.image} />

          <TextField
            className={classes.input}
            variant="outlined"
            margin="normal"
            color="secondary"
            fullWidth
            label={t('Email')}
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            placeholder="john.doe@exmaple.com"
            autoFocus
            value={email}
            onChange={(e) => {
              setError(false)
              setEmail(e.target.value)
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
          />

          {error ? (
            <Typography align="center" className={`${classes.text} ${classes.error}`}>
              {error}
            </Typography>  
          ) : (
            <Typography align="center" className={classes.text}>
              {t('Please enter an email address. A receipt for this payment would be sent to the provided email address.')}
            </Typography>
          )}
        </div>

        <div className={classes.buttonContainer}>
          <Button
            label={t('Continue')}
            invert
            textAlign='center'
            onClick={clickContinue}
          />
        </div>
      </>
    )
  )
}
