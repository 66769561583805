/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import SweetAlert from 'react-bootstrap-sweetalert'
import LinkButton from '../generic/LinkButton'
import everybyteLogo from '../../assets/logos/everybyte.png'
import openFinanceLogo from '../../assets/logos/open-finance.png'
import { PROVIDER } from '../../consts'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    maxWidth: '100%',
    margin: theme.spacing(3)
  },
}), { name: 'Finished' })

interface Props {
  isSuccess: boolean
}

export default function Finished({ isSuccess }: Props): JSX.Element {
  const { t } = useTranslation()
  const classes = useStyles()
  const [confirmed, setConfirmed] = useState(false)

  return (
    <div className={classes.paper}>
      <img
        className={classes.logo}
        src={PROVIDER === 'Everybyte' ? everybyteLogo : PROVIDER === 'Open-Finance' ? openFinanceLogo : undefined}
        alt=""
      />
      <Typography align="center">
        {isSuccess ? (
          t('Payment handled successfully!')
        ) : (
          t('Payment failed!')
        )}
      </Typography>

      {!confirmed && isSuccess ? (
        <SweetAlert
          success
          title={t('Success!')}
          onConfirm={() => null}
          customButtons={(
            <LinkButton label={t('Confirm')} onClick={() => setConfirmed(true)} />
          )}
        >
          {t('Payment complete! You will get a receipt via Email.')}
        </SweetAlert>
      ) : !confirmed && !isSuccess ? (
        <SweetAlert
          error
          title={t('Failed!')}
          onConfirm={() => null}
          customButtons={(
            <LinkButton label={t('Confirm')} onClick={() => setConfirmed(true)} />
          )}
        >
          {t('An error occurred, the payment failed!')}
        </SweetAlert>
      ) : null}
    </div>
  )
}
