import React, { useEffect, useRef, useState } from 'react'
// https://github.com/plaid/react-plaid-link
// https://plaid.com/docs/link/web
import { usePlaidLink } from 'react-plaid-link'

interface Props {
  linkToken: string;
  setLinkToken: React.Dispatch<React.SetStateAction<string>>;
  setPublicToken: React.Dispatch<React.SetStateAction<string>>;
  setAccountId: React.Dispatch<React.SetStateAction<string>>;
}

export default function Link({ linkToken, setLinkToken, setPublicToken, setAccountId }: Props): null {
  const [plaidEvent, setPlaidEvent] = useState('')
  const idRef = useRef<string>('')
  const tokenRef = useRef<string>('')

  const isLocal = window.location.origin.indexOf('http://localhost') !== -1

  const { open, exit, ready, error } = usePlaidLink({
    // When re-initializing Link after OAuth redirection, the same Link token from the first initialization must be used
    token: linkToken,
    // required for OAuth:
    // receivedRedirectUri: window.location.href,
    // if not OAuth, set to null or do not include:
    receivedRedirectUri: undefined,

    onLoad: () => {
      if (isLocal) {
        console.table({ callback: 'onLoad' })
      }
    },

    onSuccess: (public_token, metadata) => {
      if (isLocal) {
        console.table({ callback: 'onSuccess', public_token })
        console.table(metadata)
      }

      tokenRef.current = public_token
      idRef.current = metadata.accounts[0].id
    },

    onExit: (error, metadata) => {
      if (isLocal) {
        console.table({ callback: 'onExit', error })
        console.table(metadata)
      }
    },

    onEvent: (event_name, metadata) => {
      if (isLocal) {
        console.table({ callback: 'onEvent', event_name })
        console.table(metadata)
      }

      setPlaidEvent(event_name)
    },
  })

  useEffect(() => {
    if (ready) {
      console.log('Plaid Link ready!', ready)
      open()
    }
  }, [ready, open])

  useEffect(() => {
    if (error) {
      console.log('Plaid Link error!', error)
      exit({ force: true })
    }
  }, [error, exit])

  useEffect(() => {
    switch (plaidEvent) {
    case 'EXIT':
      setLinkToken('')
      break

    case 'HANDOFF':
      setAccountId(idRef.current)
      setPublicToken(tokenRef.current)
      break

    default:
      break
    }
  }, [plaidEvent]) // eslint-disable-line

  // don't render anything, just open Link
  return null
}
